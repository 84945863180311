<template>
  <div>
    <el-card class="box-card">
      <el-form
        :model="refundForm"
        :rules="rules"
        ref="refundForm"
        label-width="100px"
        class="refund-form"
      >
        <el-form-item label="订单号" prop="orderId">
          <el-input v-model="refundForm.orderId"></el-input>
        </el-form-item>
        <el-form-item label="退款金额(元)" prop="refund">
          <el-input
            v-model="refundForm.refund"
            @input="limitInput($event, 'refund')"
          ></el-input>
        </el-form-item>
        <el-form-item label="退款原因" prop="reason">
          <el-input v-model="refundForm.reason"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('refundForm')"
            >发起退款</el-button
          >
          <el-button @click="resetForm('refundForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
const urls = {
  refunds: '/pay/domestic/refunds',
};

export default {
  data() {
    return {
      refundForm: {
        orderId: '',
        reason: '',
        refund: '',
      },
      rules: {
        orderId: [{ required: true, message: '请输入订单号', trigger: 'blur' }],
      },
    };
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { orderId, reason, refund } = this.refundForm;
          const res = await this.$http.post(urls.refunds, {
            orderId,
            reason,
            refund,
          });

          const { code, msg } = res.data;
          if (code != 0) {
            this.$message({
              message: msg,
              type: 'error',
            });
            return;
          }
          this.$message({
            message: msg,
            type: 'success',
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    limitInput(value, name) {
      this.refundForm[name] =
        ('' + value) // 第一步：转成字符串
          .replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ''; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    },
  },
};
</script>
<style>
.refund-form {
  width: 480px;
}
.box-card {
  width: 100%;
}
.refunds-input {
  width: 480px;
  margin-right: 20px;
}
</style>
